#react-datepicker {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  pointer-events: none;
  z-index: 99999;
}

.react-datepicker__month-container:last-child .react-datepicker__day-names {
  margin-left: auto;
}

.react-datepicker__month-container:last-child .react-datepicker__month {
  position: relative;
  margin-left: auto;
}

.react-datepicker__month-container .react-datepicker__month {
  position: relative;
}

.react-datepicker__tab-loop {
  position: absolute;
}

.datePickerContainer-separator
  .react-datepicker__month-container
  .react-datepicker__month::after {
  position: absolute;
  content: '';
  height: calc(100% + 46px);
  width: 1px;
  background-color: #eff1f5;
  top: -46.5px;
  right: -13px;
  display: block;
}

.datePickerContainer-separator
  .react-datepicker__month-container:last-child
  .react-datepicker__month::after {
  position: absolute;
  content: '';
  height: calc(100% + 46px);
  width: 1px;
  background-color: #eff1f5;
  top: -46.5px;
  left: -12px;
  display: block;
}

.react-datepicker__header--custom {
  background-color: white;
  padding-bottom: 0px;
  border: none;
}

.react-datepicker__month {
  margin: 0;
  width: 320px;

  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.react-datepicker__week {
  display: flex;
  margin: auto;
  gap: 16px;
  width: 320px;
}

.react-datepicker__day-names {
  display: flex;
  align-items: center;
  width: 320px;
  margin-bottom: 16px;
  margin-top: 24px;
  gap: 16px;
}

.react-datepicker__day-name {
  margin: 0;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Poppins';
  font-size: 16px;
  line-height: 24px;

  color: #636874;
}

.react-datepicker__day {
  margin: 0px;

  font-family: 'Poppins';
  font-size: 16px;
  line-height: 24px;

  color: #1f1f1f;

  max-width: 32px;
  max-height: 32px;
  width: 100%;
  height: 100%;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: white !important;
}

.react-datepicker__day:hover {
  border-radius: 0;
  border: none;
}

.react-datepicker__day:empty::after {
  display: none;
}

.react-datepicker__day:has(.firstDayInTheNextMonth)::after {
  width: 32px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  left: 0px;
}

.react-datepicker__day:has(.lastDayInTheCurrentMonth)::after {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.react-datepicker__day:hover.react-datepicker__day--range-start {
  border-radius: 100%;
}

.react-datepicker__day:hover.react-datepicker__day--range-end {
  border-radius: 100%;
}

.react-datepicker__day--range-start div {
  color: white !important;
  background-color: #27325e !important;
  border-radius: 100%;
  position: relative;
  z-index: 1;
}

.react-datepicker__day--in-range {
  background-color: #ffffff;
  position: relative;
}

.react-datepicker__day--in-selecting-range {
  position: relative;
}

.react-datepicker__day--in-range:hover {
  background-color: white;
}

.react-datepicker__day--range-start.react-datepicker__day--in-range::after,
.react-datepicker__day--selecting-range-start.react-datepicker__day--in-selecting-range::after {
  display: none;
}

.react-datepicker__day--in-range::after,
.react-datepicker__day--in-selecting-range::after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: -22px;

  background-color: #f2f5ff;
  width: 54px;
  z-index: 0;

  height: 24px;
}

.react-datepicker__day--range-end div,
.react-datepicker__day--selected div,
.react-datepicker__day--selecting-range-start div,
.react-datepicker__day--selecting-range-end div {
  color: white;
  background-color: #27325e;
  border-radius: 100%;
  position: relative;
  z-index: 2;
}

.react-datepicker__week .react-datepicker__day--in-range:first-child::after,
.react-datepicker__day--in-selecting-range:first-child::after {
  width: 32px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  left: 0px;
}

.react-datepicker__week .react-datepicker__day--in-range:last-child::after,
.react-datepicker__day--in-selecting-range:last-child::after {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.react-datepicker__day--range-end::after {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.react-datepicker__day--disabled {
  color: #d7ddf6;
}

.react-datepicker__day--today {
  color: #dc5858;
}

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  src: url('../../../../public/fonts/poppins/Poppins-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  src: url('../../../../public/fonts/poppins/Poppins-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  src: url('../../../../public/fonts/poppins/Poppins-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  src: url('../../../../public/fonts/poppins/Poppins-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

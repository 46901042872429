.switch-container:hover .switch-input:checked ~ .switch-body {
  background-color: #1e65d9;
  border-color: #1e65d9;
}

.switch-container:hover .switch-input:checked ~ .switch-body .switch-thumb {
  background-color: #f2f5ff;
}

.switch-container:hover .switch-input ~ .switch-body {
  background-color: #a5b2e8;
  border-color: #a5b2e8;
}

.switch-container:hover .switch-input ~ .switch-body .switch-thumb {
  background-color: #f2f5ff;
}

.switch-input:checked ~ .switch-body .switch-thumb-large {
  transform: translateX(31px);
  background-color: white;
}

.switch-input:checked ~ .switch-body .switch-thumb-small {
  transform: translateX(24px);
  background-color: white;
}

.switch-input:checked ~ .switch-body {
  @apply bg-main border-main;
}

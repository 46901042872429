@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  color: #272727;
  background-color: #fafafa;
  font-family: 'Poppins', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

input {
  outline: none;
}

textarea:focus,
input:focus {
  outline: none;
}

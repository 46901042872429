.checkbox-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input[type='checkbox'] {
  display: none;
}

.checkbox-container input[type='checkbox'] + span .checkbox-inner {
  display: none;
}

.checkbox-container input[type='checkbox']:checked + span .checkbox-inner {
  display: block;
}

.checkbox-container input[type='checkbox']:checked + span .checkbox-outer {
  fill: #2171f2;
}

.checkbox-container
  input[type='checkbox']:not(:disabled):not(:checked)
  + span:hover
  .checkbox-outer {
  fill: #101736;
}

.checkbox-container
  input[type='checkbox']:not(:disabled):not(:checked)
  + span:hover
  #checkbox-background {
  fill: #d7ddf6;
}

.checkbox-container
  input[type='checkbox']:not(:disabled):checked
  + span:hover
  .checkbox-outer {
  fill: #1a59bf;
}

.checkbox-container
  input[type='checkbox']:not(:disabled):checked
  + span:hover
  .checkbox-inner {
  fill: #1a59bf;
}

.checkbox-container input[type='checkbox']:disabled + span .checkbox-outer {
  fill: #cfd3dc;
}
.checkbox-container input[type='checkbox']:disabled + span .checkbox-inner {
  fill: #cfd3dc;
}

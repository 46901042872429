.radio-button-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-button-container input[type='radio'] {
  display: none;
}

.radio-button-container input[type='radio'] + .circle-container .inner-circle {
  display: none;
}

.radio-button-container
  input[type='radio']:checked
  + .circle-container
  .inner-circle {
  display: block;
}

.radio-button-container
  input[type='radio']:checked
  + .circle-container
  .outer-circle {
  stroke: #2171f2;
}

.radio-button-container
  input[type='radio']:not(:disabled):not(:checked)
  + .circle-container:hover
  .outer-circle {
  fill: #d7ddf6;
  stroke: #101736;
}

.radio-button-container
  input[type='radio']:not(:disabled):checked
  + .circle-container:hover
  .inner-circle {
  fill: #1a59bf;
}

.radio-button-container
  input[type='radio']:not(:disabled):checked
  + .circle-container:hover
  .outer-circle {
  stroke: #1a59bf;
}

.radio-button-container
  input[type='radio']:disabled
  + .circle-container
  .outer-circle {
  stroke: #cfd3dc;
}
.radio-button-container
  input[type='radio']:disabled
  + .circle-container
  .inner-circle {
  fill: #cfd3dc;
}

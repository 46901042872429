:root {
  --toastify-color-error: #bc091f;
  --toastify-color-success: #6bc398;
  --toastify-color-warning: #f04e24;
  --toastify-toast-min-height: 40px;

  --toastify-text-color-light: #272727;
}

.Toastify__toast {
  border-radius: 5px;
  box-shadow: 2px 4px 16px 0px rgba(178, 178, 178, 0.25);
}

.Toastify__toast::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  width: 4px;
}

.Toastify__toast--error::before {
  background-color: var(--toastify-color-error);
}

.Toastify__toast--success::before {
  background-color: var(--toastify-color-success);
}

.Toastify__toast--warning::before {
  background-color: var(--toastify-color-warning);
}

.Toastify__close-button {
  align-self: center;
}
